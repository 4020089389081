import Header from "./components/Header";
import Main from "./components/Main";
import Content from "./components/Content";

function App() {
  return (
    <div>
      <Header />
      <Main />
      <Content />
      {/* <button onClick={handleInstallClick}>📲 Установить PWA</button> */}
    </div>
  );
}

export default App;
